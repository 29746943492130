<template>
  <ProjectHeader />
  <ProjectIntro />

  <section id="hero">
    <div class="container">
      <img src="/images/gotiny_hero_expanded.jpg" alt="GoTiny Screenshot" />
    </div>
  </section>

  <section id="features">
    <div class="container">
      <feature img="gotiny_feature_snelheid.mp4">
        <template #title v-if="lang === 'nl'">Gemaakt voor snelheid</template>
        <template #title v-else>Made for speed</template>
        <template #body v-if="lang === 'nl'">
          <p>
            Het verkrijgen van een korte link moet zo snel en pijnloos mogelijk zijn. Bij het maken van zowel de website
            als de API waar GoTiny op draait, is bij iedere stap gedacht aan het optimaliseren van de snelheid voor de
            gebruiker.
          </p>
          <p>
            De user interface wordt niet ververst na het maken van een korte link, zodat de gebruiker niet hoeft te
            wachten tot de pagina opnieuw geladen is. De app verandert simpelweg naar een scherm waarin meteen duidelijk
            wordt wat de korte link is.
          </p>
        </template>
        <template #body v-else>
          <p>
            To obtain a short link has to be quick and painless. While creating both the website and the API that GoTiny
            runs on, I thought about how to optimize speed and reduce hassle, every step of the way.
          </p>
          <p>
            The user interface doesn't refresh after submitting the form on the website, so the user doesn't have to
            wait for another page loading. The website simply changes to a show a screen where it's immediately clear to
            the user what the short link is.
          </p>
        </template>
      </feature>

      <feature img="gotiny_feature_readability.jpg">
        <template #title v-if="lang === 'nl'">Is dat een O of een 0?</template>
        <template #title v-else>Is that an O or 0?</template>
        <template #body v-if="lang === 'nl'">
          <p>
            GoTiny genereert voor iedere link een unieke code die de bezoeker naar de originele website doorstuurt. Bij
            het ontwerp van deze korte links stond één ding centraal: het oplezen en intypen van de link moet zo
            gemakkelijk mogelijk zijn.
          </p>
          <p>
            Zo bevatten links die met GoTiny worden gemaakt geen speciale karaters. Hoofdletters en karakters die te
            veel op elkaar lijken ook uitgesloten. Zelfs met deze restricties zijn er genoeg combinaties mogelijk om tot
            het jaar 2030 iedere seconde een nieuwe link op te vragen. Tegen die tijd zal ik een karakter toevoegen.
          </p>
        </template>
        <template #body v-else>
          <p>
            GoTiny generates a short code for every link that takes the user to the original website that was provided.
            During construction of those links, I focused on one thing: sharing them should be as easy as possible.
          </p>
          <p>
            Links generated by GoTiny don't include special characters. Capital letters and characters that closely
            resemble another character (e.g. g/q/9 or i/j/l/1) are also excluded. Even with these restrictions in place,
            there's enough possible combinations to generate a new link every second until the year 2030. By then, I'll
            add a character.
          </p>
        </template>
      </feature>

      <feature img="gotiny_feature_integration.jpg">
        <template #title v-if="lang === 'nl'">Overal te integreren</template>
        <template #title v-else>Integrate everywhere</template>
        <template #body v-if="lang === 'nl'">
          <p>
            Hoewel de website van GoTiny een snelle manier is om een korte link te maken, ligt het hart van de
            funcionaliteit in de API. De API is beschikbaar voor iedereen, zodat ontwikkelaars met minimale moeite korte
            links kunnen maken in hun projecten.
          </p>
          <p>
            GoTiny filtert zelf de link uit iedere tekst die gegeven wordt. Een ontwikkelaar die gebruik wil maken van
            de API, hoeft dus geen extra werk te doen om de lange url schoon aan te leveren. Zo kan GoTiny ingezet
            worden in een chatbot in slechts een paar regels code.
          </p>
        </template>
        <template #body v-else>
          <p>
            Despite gotiny.cc being an easy way to create short links, the core of generating tiny links quickly and
            easily is the API behind it. The API is accessible to be used by everyone, enabling developers to create
            tiny links in their projects with minimal effort.
          </p>
          <p>
            GoTiny automatically filters the url out of any string that a user enters. This way, developers using the
            SDK don't have to provide a clean link, but can just feed an entire paragraph into GoTiny. The API will find
            the url and return a short link.
          </p>
        </template>
      </feature>
    </div>
  </section>

  <section id="features-small">
    <div class="container">
      <feature-small img="gotiny_feature_small_customlinks.jpg">
        <template #title>Custom links</template>
        <template #body v-if="lang === 'nl'">
          De <a href="https://github.com/robvanbakel/gotiny-api" target="_blank">GoTiny API</a> laat gebruikers custom
          links maken. In plaats van een willekeurig gegenereerde code die naar de orginele URL verwijst te gebruiker,
          kunnen gebuikers een eigen identifier invoeren waarmee de link wordt gemaakt.
        </template>
        <template #body v-else>
          The <a href="https://github.com/robvanbakel/gotiny-api" target="_blank">GoTiny API</a> lets users create their
          own custom links. Instead of a randomly generated 6-character code to resolve the original URL, users can
          provide their own identifier that will be used to generate the GoTiny link.
        </template>
      </feature-small>

      <feature-small img="gotiny_feature_small_localhistory.jpg">
        <template #title v-if="lang === 'nl'">Lokale geschiedenis</template>
        <template #title v-else>Local history</template>
        <template #body v-if="lang === 'nl'">
          Hoewel GoTiny geen gebuikersdata opslaat in zijn database of op de server, wordt er een lokale kopie bewaard
          in de browser om het makkelijk te maken een eerder kortgemaakte link terug te vinden.
        </template>
        <template #body v-else>
          Although GoTiny does not store any user information in its database or on its server, a local copy is being
          kept in the user's browser to make it easy to go go back and reshare a previously shortened link.
        </template>
      </feature-small>

      <feature-small img="gotiny_feature_small_javascriptsdk.jpg">
        <template #title>JavaScript SDK</template>
        <template #body v-if="lang === 'nl'">
          Om het voor ontwikkelaars nog makkelijker te maken GoTiny te integreren heb ik een een JavaScript SDK gemaakt,
          die betere error handling en uitgebreidere response objects biedt. De SDK is
          <a href="https://www.npmjs.com/package/gotiny" target="_blank">beschikbaar op NPM</a>.
        </template>
        <template #body v-else>
          The GoTiny API is easy to use, but for an even better experience for developers, I created a JavaScript SDK,
          providing extra features like improved error-handling and extended return objects. The SDK is
          <a href="https://www.npmjs.com/package/gotiny" target="_blank">available on NPM</a>.
        </template>
      </feature-small>
    </div>
  </section>

  <Footer />
</template>

<script>
import ProjectHeader from '@/components/layout/ProjectHeader';
import ProjectIntro from '@/components/layout/ProjectIntro';
import FeatureSmall from '@/components/layout/FeatureSmall';
import Feature from '@/components/layout/Feature';
import Footer from '@/components/layout/Footer';

export default {
  components: {
    ProjectHeader, ProjectIntro, FeatureSmall, Feature, Footer,
  },
  computed: {
    lang() {
      return this.$store.getters.lang;
    },
    meta() {
      return this.$store.getters.meta;
    },
    project() {
      return this.$store.getters.meta.projects.find((project) => project.name === this.$route.name);
    },
  },
};
</script>

<style></style>
